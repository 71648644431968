// external
import { Box, Button, Card, Fade, SxProps, Theme } from '@mui/material';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// internal
import { IProfile } from '@guider-global/shared-types';
import { useLocalization, useMobileMediaQuery } from 'hooks';

// components
import { GroupPreview } from 'components';

// store

import { IGroupGuideForm } from 'store/slices/formsSlice';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';

interface GroupCardProps {
  guide: Partial<IProfile>;
  data: IGroupGuideForm;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps<Theme>;
  isBestMatch?: boolean;
  action?: () => void;
  blurred?: boolean;
  isLoading?: boolean;
  traineeProfiles?: string[];
  userRole?: string | undefined;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  traineeProfiles,
  guide,
  data,
  sx = {},
  isBestMatch,
  action,
  blurred,
  isLoading,
  userRole,
}) => {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const [isBlurred, setIsBlurred] = useState(blurred);
  const isMobile = useMobileMediaQuery();

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  if (!program || !role) {
    return <></>;
  }

  const programType = program.program_details?.program_type;
  const groupCopy = programType?.program_type_text?.variations?.group;

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'auto',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'stretch',
        minHeight: '300px',
        position: 'relative',
        margin: 3,
        ...sx,
      }}
    >
      <Fade in={isBlurred} appear={false}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, .5)',
            backdropFilter: 'blur(8px)',
            zIndex: 1,
          }}
        >
          <Button
            data-cy="components_Guide_GroupCard_guide-card-button"
            variant="contained"
            color="info"
            onClick={() => setIsBlurred(false)}
          >
            {groupCopy?.registration?.profile_card?.reveal_group_button_label}
          </Button>
        </Box>
      </Fade>
      <GroupPreview
        userRole={userRole}
        traineeProfiles={traineeProfiles}
        showBestMatch={isBestMatch}
        programSlug={programSlug}
        profileData={guide}
        groupData={data}
        action={action}
        isLoading={isLoading}
        sxProps={{ width: '100%' }}
      />
    </Card>
  );
};
