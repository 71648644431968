import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganization } from '@guider-global/sanity-hooks';
import { theme } from '@guider-global/ui';
import { Box, useTheme } from '@mui/material';
import { useLocalization } from 'hooks';
import React, { useRef } from 'react';
import { deepMerge } from 'utils';

type colorOption =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

export function Background() {
  const ref = useRef<HTMLElement>(null);

  const offsetWidth = ref?.current?.offsetWidth;

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { organization } = useOrganization({ organizationSlug, localeCode });

  // Styling
  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const option = organization?.dashboard_page?.background_color;

  const selectedColor = option
    ? combinedPalette[option as colorOption].main
    : '#F9FAFB';

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: '540px',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: selectedColor,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: 0,
          pt: '540px',
          overflow: 'hidden',
          '&:after': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            mt: '-200px',
            borderTop: '200px solid transparent',
            borderRight: `${offsetWidth}px solid #F9FAFB`,
          },
        }}
      />
    </Box>
  );
}
