import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { useEffect } from 'react';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { handleCustomFormErrors } from 'utils';
import { TFormDropdownTyped } from './types';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useLocalization } from 'hooks';

// TODO Rename to FormDropdown when FormDropdown deprecated
export const FormDropdownTyped = <
  TFieldValues extends FieldValues,
  FieldType extends PathValue<TFieldValues, Path<TFieldValues>> | undefined,
>({
  name,
  control,
  errors,
  options,
  rules,
  unregister,
  enabled = true,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  validateErrorMessage,
  requiredErrorMessage,
  emptyOption = false,
  helperText,
  defaultValue,
  ...selectProps
}: TFormDropdownTyped<TFieldValues>) => {
  const sx = {
    label: { color: 'text.secondary', fontWeight: 400 },
    display: !enabled ? 'none' : undefined,
    ...selectProps.sx,
  };
  const errorColor = 'error.main';
  const errorSx = errors[name]
    ? {
        color: errorColor,
      }
    : undefined;

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const genericErrors = baseLanguage?.generic_errors;

  const noneLabel = genericErrors?.none_select_label;

  useEffect(() => {
    if (!enabled && unregister) {
      unregister(name);
    } else if (!enabled && !unregister) {
      const message = `The FormField ${name} is not enabled but still registered in the React Hook Form - you probably forgot to pass the 'unregister' prop to the FormField!`;
      console.log(message);
      throw new Error(message);
    }
  }, [enabled, name, unregister]);

  const renderSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  if (!enabled) return null;

  return (
    <FormControl
      fullWidth
      sx={{
        mt: 2.5,
      }}
    >
      <InputLabel
        id={`${name}-label`}
        sx={{
          color: errors[name] ? errorColor : 'text.secondary',
          fontWeight: 400,
          '&.Mui-focused': {
            color: 'info.main',
          },
        }}
      >
        {selectProps.label}
      </InputLabel>
      <Controller<TFieldValues>
        name={name}
        control={control}
        rules={rules}
        defaultValue={(defaultValue as FieldType) ?? ('' as FieldType)}
        render={({ field: { onChange, value } }) => (
          <Select
            fullWidth
            color="info"
            labelId={`${name}-label`}
            onChange={onChange}
            value={value}
            error={!!errors[name]}
            {...selectProps}
            sx={sx}
          >
            {emptyOption && (
              <MenuItem key={noneLabel} value="">
                <em>{noneLabel}</em>
              </MenuItem>
            )}
            {renderSelectOptions()}
          </Select>
        )}
      />
      <FormHelperText sx={errorSx}>
        <>
          {handleCustomFormErrors(
            errors[name],
            maxLengthErrorMessage,
            minLengthErrorMessage,
            validateErrorMessage,
            requiredErrorMessage,
          ) ?? helperText}
        </>
      </FormHelperText>
    </FormControl>
  );
};
