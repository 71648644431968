//external
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

export interface IButtonAction {
  label: string | undefined;
  action: () => void;
  variant?: 'contained' | 'outlined' | 'text';
  isLoadingButton?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  textColor?: string;
  disabled?: boolean;
  dataCyLabel?: string;
}

interface IActionButtonProps {
  buttonAction: IButtonAction;
  isLoading?: boolean;
}

export const ActionButton: React.FC<IActionButtonProps> = ({
  buttonAction,
  isLoading,
}) => {
  if (buttonAction.isLoadingButton) {
    return (
      <LoadingButton
        disabled={buttonAction.disabled}
        loading={isLoading}
        data-cy={buttonAction.dataCyLabel}
        sx={{
          fontWeight: 600,
          width: { xs: '100%', md: 'auto' },
          my: { xs: 1, md: 0 },
          ...(buttonAction.variant === 'outlined' && {
            bgcolor: 'white',
          }),

          ...(buttonAction.textColor && { color: buttonAction.textColor }),
        }}
        color={buttonAction.color ?? 'info'}
        variant={buttonAction.variant ?? 'contained'}
        onClick={buttonAction.action}
      >
        {buttonAction.label}
      </LoadingButton>
    );
  } else {
    return (
      <Button
        disabled={isLoading}
        data-cy={buttonAction.dataCyLabel}
        sx={{
          fontWeight: 600,
          width: { xs: '100%', md: 'auto' },
          my: { xs: 1, md: 0 },
          ...(buttonAction.variant === 'outlined' && {
            bgcolor: 'white',
          }),
          ...(buttonAction.textColor && { color: buttonAction.textColor }),
        }}
        color={buttonAction.color ?? 'info'}
        variant={buttonAction.variant ?? 'contained'}
        onClick={buttonAction.action}
      >
        {buttonAction.label}
      </Button>
    );
  }
};
