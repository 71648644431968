import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { handleCustomFormErrors } from 'utils';
import { TFormCheckbox } from './types';

export const FormCheckbox = <
  TFieldValues extends FieldValues,
  FieldType extends PathValue<TFieldValues, Path<TFieldValues>> | undefined,
>({
  name,
  control,
  errors,
  rules,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  validateErrorMessage,
  requiredErrorMessage,
  label,
  labelAction,
  fullWidth,
  containerSx,
  defaultValue,
  ...checkBoxProps
}: TFormCheckbox<TFieldValues> & { children?: ReactNode }) => {
  const errorColor = 'error.main';
  const errorSx = errors[name]
    ? {
        color: errorColor,
      }
    : undefined;

  const toBoolean = (value: string | boolean) => {
    if (typeof value === 'string') {
      if (value === 'true') return true;
      return false;
    }
    if (typeof value === 'boolean') {
      return value;
    }
    return false;
  };

  return (
    <FormControl fullWidth={fullWidth} sx={containerSx}>
      <FormControlLabel
        label={
          label && (
            <Typography
              variant="body1"
              onClick={
                labelAction
                  ? (e: React.MouseEvent) => {
                      e.preventDefault();
                      labelAction();
                    }
                  : undefined
              }
            >
              {label}
            </Typography>
          )
        }
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={
              toBoolean(defaultValue as string | boolean) as FieldType
            }
            render={({ field: { onChange, value } }) => {
              const validatedValue = toBoolean(value);
              return (
                <Checkbox
                  color="info"
                  onChange={onChange}
                  value={validatedValue}
                  checked={validatedValue}
                  {...checkBoxProps}
                />
              );
            }}
          />
        }
      />
      <FormHelperText sx={errorSx}>
        <>
          {handleCustomFormErrors(
            errors[name],
            maxLengthErrorMessage,
            minLengthErrorMessage,
            validateErrorMessage,
            requiredErrorMessage,
          )}
        </>
      </FormHelperText>
    </FormControl>
  );
};
