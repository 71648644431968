// external
import { useEffect } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';

// internal
import { handleCustomFormErrors } from 'utils';

// types
import { TFormTextFieldTyped } from './types';

// TODO Rename to FormTextField when FormTextField deprecated
export const FormTextFieldTyped = <
  TFieldValues extends FieldValues,
  FieldType extends PathValue<TFieldValues, Path<TFieldValues>> | undefined,
>({
  name,
  control,
  errors,
  rules,
  unregister,
  enabled = true,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  validateErrorMessage,
  requiredErrorMessage,
  defaultValue,
  ...textFieldProps
}: TFormTextFieldTyped<TFieldValues>) => {
  const multiline =
    !!textFieldProps.rows &&
    typeof textFieldProps.rows === 'number' &&
    textFieldProps.rows > 1;
  const sx = {
    label: { color: 'text.secondary', fontWeight: 400 },
    display: !enabled ? 'none' : undefined,
    ...textFieldProps.sx,
  };
  const errorColor = 'error.main';
  const errorSx = errors[name]
    ? {
        color: errorColor,
      }
    : undefined;

  useEffect(() => {
    if (!enabled && unregister) {
      unregister(name);
    } else if (!enabled && !unregister) {
      const message = `The FormField ${name} is not enabled but still registered in the React Hook Form - you probably forgot to pass the 'unregister' prop to the FormField!`;
      console.log(message);
      throw new Error(message);
    }
  }, [enabled, name, unregister]);

  if (!enabled) return null;

  return (
    <FormControl fullWidth sx={{ mt: 2.5 }}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={(defaultValue as FieldType) ?? ('' as FieldType)}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            color="info"
            onChange={onChange}
            value={value as FieldType}
            helperText={handleCustomFormErrors(
              errors[name],
              maxLengthErrorMessage,
              minLengthErrorMessage,
              validateErrorMessage,
              requiredErrorMessage,
            )}
            error={!!errors[name]}
            multiline={multiline}
            {...textFieldProps}
            sx={sx}
            InputProps={
              errors[name]
                ? {
                    sx: {
                      ...errorSx,
                      alignItems: multiline ? 'start' : 'center',
                      '& .MuiInputAdornment-root': {
                        height: '100%',
                        marginLeft: 1,
                        marginRight: 0,
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <InfoOutlined sx={errorSx} />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        )}
      />
    </FormControl>
  );
};
