// external
import CalendarToday from '@mui/icons-material/CalendarToday';
import {
  Box,
  Button,
  Card,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// internal
import { IGoal } from '@guider-global/shared-types';
import { useLocalization, useMixpanelEvents, useMobileMediaQuery } from 'hooks';
import { hexToRGB } from 'utils';

// Hooks
import { useBaseLanguage } from '@guider-global/sanity-hooks';

// store
import {
  getFullDateFormat,
  getLanguageFromCMS,
  getSubDomain,
} from '@guider-global/front-end-utils';
import { MDIcons, SanityIcon } from 'components';

interface IGoalsItemProps {
  goal: Partial<IGoal>;
  sx?: SxProps<Theme>;
  viewPath?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  linkGoalVariant?: boolean;
  handleLinkGoal?: VoidFunction;
  goalName?: string;
  goalSlug?: string;
  goalIcon?: string;
}

export const GoalsItem: React.FC<IGoalsItemProps> = ({
  goal,
  sx = {},
  viewPath,
  onClick,
  linkGoalVariant = false,
  handleLinkGoal,
  goalSlug,
  goalName,
  goalIcon,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const navigate = useNavigate();

  const locale = getLanguageFromCMS(baseLanguage?.language_code);

  const formattedDate = getFullDateFormat(baseLanguage?.date_format);

  if (!goal.createdAt) {
    return <></>;
  }

  const viewPathToUse = viewPath || `/goals/${goal.id}`;

  const goalDate = format(parseISO(goal.createdAt.toString()), formattedDate, {
    locale,
  });

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        ...sx,
      }}
      variant="outlined"
      onClick={onClick}
      data-cy={`components_Goals_GoalsItem_goalDisplay_${goal.objective}`}
    >
      {!isMobile && (
        <Box
          sx={{
            width: '58px',
            minWidth: '58px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: `rgba(${hexToRGB(theme.palette.info.main)}, 0.08)`,
            color: theme.palette.info.main,
            fontSize: '30px',
          }}
        >
          <SanityIcon name={goalIcon as MDIcons} />
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1.5,
          px: 2,
          position: 'relative',
        }}
      >
        <Box sx={{ width: '70%' }}>
          <Typography variant="caption">{goalName ?? goalSlug}</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CalendarToday
              sx={{ mr: 1, color: 'text.disabled', fontSize: 20 }}
            />
            {isMobile ? (
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="caption"
                  sx={{ mr: 1, whiteSpace: 'nowrap' }}
                >
                  {goalDate}
                </Typography>
              </Box>
            ) : (
              <Typography variant="caption">{goalDate}</Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-end',
            ...(isMobile && {
              justifyContent: 'center',
            }),
          }}
        >
          {linkGoalVariant ? (
            <Button
              onClick={handleLinkGoal}
              variant="outlined"
              size="small"
              color="info"
              sx={{
                whiteSpace: 'nowrap',
                fontWeight: 600,
                color: theme.palette.text.secondary,
                borderColor: theme.palette.divider,
              }}
            >
              {baseLanguage?.globals?.goals?.link_goal_button_label}
            </Button>
          ) : (
            <Button
              data-cy={`components_Goals_GoalsItem_view-goal-button_${goal.objective}`}
              onClick={() => {
                trackScreenTimeAndClick('Goal - Goal viewed');
                navigate(viewPathToUse);
              }}
              variant="outlined"
              size="small"
              color="info"
              sx={{
                whiteSpace: 'nowrap',
                fontWeight: 600,
                color: theme.palette.text.secondary,
                borderColor: theme.palette.divider,
              }}
            >
              {baseLanguage?.globals?.goals?.view_goal_button_label}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
};
