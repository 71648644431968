// external
import React, { useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import {
  ChatClientProvider,
  ChatThreadClientProvider,
} from '@azure/communication-react';

import { ChatComponents } from './ChatComponents';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectRelationshipThread,
  unsetIsUnreadMessages,
} from 'store/slices/relationshipThreadsSlice';
import { useChatParticipants } from 'hooks/useChatParticipants';
import { IGroup, IRelationship } from '@guider-global/shared-types';
import { RelationshipGroupGuideModal } from 'modals';

export type CustomChatCompositeProps = {
  relationship: IRelationship | undefined;
  isGuide: boolean;
  isGroupProgram: boolean;
  isNotLiveGroup: boolean;
  threadId: string;
  archived?: boolean;
  archivedLabel?: string;
  onSendMessage?: () => Promise<void>;
};

export const CustomChatComposite: React.FC<CustomChatCompositeProps> = ({
  relationship,
  isGuide,
  isGroupProgram,
  isNotLiveGroup,
  threadId,
  archived,
  archivedLabel,
  onSendMessage,
}) => {
  const group = relationship?.group as Partial<IGroup>;
  const isGuideModalOpen =
    isGuide &&
    isGroupProgram &&
    !group?.welcomeMessage &&
    !relationship?.isConcluded;

  // Redux
  const dispatch = useAppDispatch();
  const { thread, statefulChatClient } = useAppSelector(
    selectRelationshipThread(threadId),
  );
  const { reqChatParticipants } = useChatParticipants({});

  const chatThreadClient = statefulChatClient?.getChatThreadClient(threadId);

  const handleMessageSeen = useCallback(
    async (messageId: string) => {
      if (thread?.isUnreadMessages && chatThreadClient) {
        dispatch(unsetIsUnreadMessages({ threadId }));
        await reqChatParticipants({
          url: `chatParticipants?threadId=${threadId}`,
          method: 'PATCH',
          data: {
            isUnreadMessages: false,
          },
        });
        await chatThreadClient.sendReadReceipt({ chatMessageId: messageId });
      }
    },
    [
      chatThreadClient,
      dispatch,
      reqChatParticipants,
      thread?.isUnreadMessages,
      threadId,
    ],
  );

  if (!statefulChatClient || !chatThreadClient) {
    return <CircularProgress color="secondary" size={120} />;
  }

  return (
    <ChatClientProvider chatClient={statefulChatClient}>
      <ChatThreadClientProvider chatThreadClient={chatThreadClient}>
        {chatThreadClient && (
          <ChatComponents
            isGuide={isGuide}
            isGroupProgram={isGroupProgram}
            isNotLiveGroup={isNotLiveGroup}
            archived={archived}
            archivedLabel={archivedLabel}
            onMessageSeen={handleMessageSeen}
            onSendMessage={onSendMessage}
          />
        )}
        {isGuide && (
          <RelationshipGroupGuideModal
            relationship={relationship}
            isModalOpen={isGuideModalOpen}
          />
        )}
      </ChatThreadClientProvider>
    </ChatClientProvider>
  );
};
