// external
import React from 'react';
import { Box } from '@mui/material';

// internal
import { hexToRGB } from 'utils';

// types
import { SanityIcon } from '@guider-global/shared-types/lib/sanity/SanityIcon';
import { MDIcons, SanityIcon as IconSanity } from 'components';

const centeredCircleStyles = {
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface IconCircleProps {
  icon?: SanityIcon;
  color: string;
  size: 'small' | 'medium' | 'large';
  iconColor?: string;
}

export const IconCircle: React.FunctionComponent<IconCircleProps> = ({
  icon,
  color,
  size,
  iconColor = 'white',
}) => {
  let diameter1 = '50px';
  let diameter2 = '40px';
  let diameter3 = '30px';
  let iconSize = '16px';

  if (size === 'medium') {
    diameter1 = '70px';
    diameter2 = '60px';
    diameter3 = '50px';
    iconSize = '20px';
  }

  if (size === 'large') {
    diameter1 = '110px';
    diameter2 = '90px';
    diameter3 = '65px';
    iconSize = '26px';
  }

  return (
    <Box
      sx={{
        width: diameter1,
        height: diameter1,
        backgroundColor: `rgba(${hexToRGB(color)}, 0.08)`,
        ...centeredCircleStyles,
        m: size === 'small' ? '8px' : 1.5,
      }}
    >
      <Box
        sx={{
          width: diameter2,
          height: diameter2,
          backgroundColor: `rgba(${hexToRGB(color)}, 0.5)`,
          ...centeredCircleStyles,
        }}
      >
        <Box
          sx={{
            width: diameter3,
            height: diameter3,
            backgroundColor: color,
            color: iconColor,
            ...centeredCircleStyles,
            fontSize: iconSize,
          }}
        >
          <IconSanity name={icon?.name as MDIcons} />
        </Box>
      </Box>
    </Box>
  );
};
