import {
  Typography,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Box,
} from '@mui/material';
import { IErrorProps } from 'components/GenericError';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Warning from '@mui/icons-material/Warning';
import Mail from '@mui/icons-material/Mail';
import Logout from '@mui/icons-material/Logout';
import Login from '@mui/icons-material/Login';

export const ErrorComponent = ({
  message,
  code,
  action,
  onClickLogin,
  onClickLogout,
  authorized,
}: IErrorProps) => {
  return (
    <Box
      sx={{
        maxWidth: '450px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
      }}
    >
      <Warning
        sx={{
          color: '#EA0000',
          height: '51px',
          width: '58px',
          marginBottom: 3,
        }}
      />
      <Typography variant="h4" component="h1" sx={{ fontSize: '34px' }}>
        An error has occurred
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ marginTop: 1.5, marginBottom: 3 }}
      >
        Please try again in a few minutes.
      </Typography>
      <Alert severity="info" color="info" icon={<></>}>
        <Accordion
          defaultExpanded
          variant="outlined"
          sx={{
            textAlign: 'left',
            border: 'none',
            backgroundColor: 'inherit',
            padding: 0,
          }}
        >
          <AccordionSummary
            aria-controls="error-content"
            id="error-header"
            sx={{ padding: 0, pointerEvents: 'none' }}
          >
            <InfoOutlinedIcon color="info" sx={{ mr: 1 }} />
            <Typography>Error: {code}</Typography>
          </AccordionSummary>
          <AccordionDetails id="error-content">{message}</AccordionDetails>
        </Accordion>
      </Alert>

      <Divider />
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ marginTop: 3, marginBottom: 3 }}
      >
        If this issue persists please contact support using the button below.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: 1,
          mb: 2,
        }}
      >
        <Button
          data-cy="components_GenericError_mail-support-button"
          href={`mailto:support@guider.app?subject=Error while using the Guider Application&body=Hi,\r\rI experienced the following error while trying to use the Guider platform at ${window.location.href}:\r\rError Code: ${code}\rError Message: ${message}\r\rThanks in advance for your help!`}
          variant="outlined"
          color="primary"
          startIcon={<Mail />}
          size="small"
        >
          Contact Support
        </Button>
        {authorized && onClickLogout ? (
          <Button
            data-cy="components_GenericError_logout-button"
            onClick={onClickLogout}
            variant="outlined"
            color="primary"
            startIcon={<Logout />}
            size="small"
          >
            Logout
          </Button>
        ) : undefined}
        {!authorized && onClickLogin ? (
          <Button
            data-cy="components_GenericError_login-button"
            onClick={onClickLogin}
            variant="outlined"
            color="primary"
            startIcon={<Login />}
            size="small"
          >
            Login
          </Button>
        ) : undefined}
        {action ? (
          <Button
            data-cy="components_GenericError_action"
            onClick={action.onclick}
            variant="outlined"
            color="primary"
            startIcon={action.icon}
            size="small"
          >
            {action.label}
          </Button>
        ) : undefined}
      </Box>
    </Box>
  );
};

export default ErrorComponent;
