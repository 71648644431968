/**
 * This component is a flexible animation wrapper. As a default it renders both slide and fade animations once,
 *  starting from 20% screen hight on scroll. It's behaviour can be changed with the following params.
 *
 * @sx The sx props that will be passed down to the Box wrapping the content.
 * @animationType Determines the type of animation between: "slide", "fade" and "both".
 * @animationDelay Number of miliseconds by which the animation will be delayed.
 * @slideDirection Determines the direction of the slide, if enabled above.
 * IMPORTANT: Changing slide direction between vertical and horizontal depending on screen size doesn't work as expected because of delay in determining the screen size. Animation delay can remadiate it.
 * @slideOffset The number of vh or vw (depending on direction), by which the elements initial position will be shifted before animation.
 * IMPORTANT: Horizontal offset can move the element out of the viewport if too big, preventing the animation from being triggered.
 * Vertical offset will influence the triggering of animation by screen height.
 * @slideDuration Duration of the slide animation in seconds.
 * @fadeDuration Duration of the fade in animation in seconds.
 * @externalTrigger Boolean value passed from a parent element, which can be used instead of the scroll to trigger the animation.
 * @screemHeightTrigger The height in percent from the bottom of the viewport on which the animation will be triggered.
 * @oneTimeAnimation Determines if the animation is supposed to happen once, or each time the object enters the viewport.
 *
 */
//external
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';

export interface IAnimationWrapper {
  sx?: SxProps<Theme>;
  animationType?: 'slide' | 'fade' | 'both';
  animationDelay?: number;
  slideDirection?: 'up' | 'down' | 'left' | 'right';
  slideOffset?: number;
  slideDuration?: number;
  fadeDuration?: number;
  externalTrigger?: boolean;
  screenHeightTrigger?: number;
  oneTimeAnimation?: boolean;
}

export const AnimationWrapper: React.FC<
  PropsWithChildren<IAnimationWrapper>
> = ({
  children,
  sx = {},
  animationType = 'both',
  animationDelay = 0,
  slideDirection = 'up',
  slideOffset = 20,
  slideDuration = 1,
  fadeDuration = 1,
  externalTrigger = undefined,
  screenHeightTrigger,
  oneTimeAnimation = true,
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const component = componentRef.current;
    let timer: NodeJS.Timeout;
    if (externalTrigger === undefined) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (oneTimeAnimation && entries[0].isIntersecting) {
            timer = setTimeout(() => {
              setShouldAnimate(true);
            }, animationDelay);
            observer.disconnect();
          } else {
            timer = setTimeout(() => {
              setShouldAnimate(entries[0].isIntersecting);
            }, animationDelay);
          }
        },
        screenHeightTrigger
          ? { rootMargin: `0% 0% -${screenHeightTrigger}% 0%` }
          : {},
      );
      if (component) observer.observe(component);
      return () => {
        observer.disconnect();
        clearTimeout(timer);
      };
    } else {
      return;
    }
  }, [animationDelay, externalTrigger, oneTimeAnimation, screenHeightTrigger]);

  useEffect(() => {
    externalTrigger !== undefined && setShouldAnimate(externalTrigger);
  }, [externalTrigger]);

  let transformStyles: React.CSSProperties['transform'];
  let animationStyles: React.CSSProperties;

  switch (slideDirection) {
    case 'up': {
      transformStyles = `translateY(${slideOffset}vh)`;
      break;
    }
    case 'down': {
      transformStyles = `translateY(-${slideOffset}vh)`;
      break;
    }
    case 'left': {
      transformStyles = `translateX(${slideOffset}vw) `;
      break;
    }
    case 'right': {
      transformStyles = `translateX(-${slideOffset}vw)`;
      break;
    }
    default: {
      transformStyles = `translateX(${slideOffset}vh)`;
    }
  }

  switch (animationType) {
    case 'fade': {
      animationStyles = {
        opacity: 0,
        visibility: 'hidden',
        transition: `opacity ${fadeDuration}s ease-in-out,`,
        willChange: 'opacity',
      };
      break;
    }
    case 'slide': {
      animationStyles = {
        transform: `${transformStyles}`,
        transition: `transform ${slideDuration}s ease-in-out`,
        willChange: 'transform',
      };
      break;
    }
    case 'both':
    default: {
      animationStyles = {
        opacity: 0,
        transform: `${transformStyles}`,
        transition: `opacity ${fadeDuration}s ease-in-out, transform ${slideDuration}s ease-in-out`,
        willChange: 'opacity, visibility, transform',
      };
      break;
    }
  }

  const inViewStyles: React.CSSProperties = {
    opacity: 1,
    transform: 'none',
    transition: `opacity ${fadeDuration}s ease-in-out, transform ${slideDuration}s ease-in-out`,
  };

  return (
    <Box
      ref={componentRef}
      sx={{ ...(shouldAnimate ? inViewStyles : animationStyles), ...sx }}
    >
      {children}
    </Box>
  );
};
