import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export interface IDropdownAction {
  label: string;
  action: () => void;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
}

interface IDropdownMenuProps extends ButtonProps {
  label: string | undefined;
  actions: IDropdownAction[];
  containerSx?: SxProps<Theme>;
  onDropdownButtonClick?: () => void;
}

export const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  label,
  actions,
  containerSx = {},
  variant = 'contained',
  onDropdownButtonClick,
  ...rest
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onDropdownButtonClick?.();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderActions = () =>
    actions.map((action, index) => {
      const { color = 'info' } = action;
      return (
        <MenuItem
          disabled={action.disabled ?? false}
          key={`dropdown-action-${index}`}
          onClick={action.action}
          sx={{ color: theme.palette[color].main }}
        >
          {action.label}
        </MenuItem>
      );
    });

  return (
    <Box sx={{ ...containerSx }}>
      <Button
        id="dropdown-menu-button"
        data-cy="components_DropdownMenu_dropdown-menu-button"
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant={variant}
        color="info"
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDown
            sx={{
              transition: '0.3s ease-in-out',
              transform: open ? 'scaleY(-1)' : '',
              backcol: 'red',
            }}
          />
        }
        fullWidth
        {...rest}
      >
        {label}
      </Button>
      <Menu
        id="dropdown-menu"
        MenuListProps={{
          'aria-labelledby': 'dropdown-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
      >
        {renderActions()}
      </Menu>
    </Box>
  );
};
