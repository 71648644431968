// external
import ReactConfetti from 'react-confetti';
import { Box, useTheme } from '@mui/material';

// store
import { useAppSelector } from 'store/hooks';
import { selectConfetti } from 'store/slices/appSlice';

// hooks
import { useWindowDimensions } from 'hooks';

export const Confetti = () => {
  const theme = useTheme();
  const { width, height } = useWindowDimensions();
  const confettiConfig = useAppSelector(selectConfetti);
  const shouldShowConfetti = confettiConfig.show;

  const { primary, secondary } = theme.palette;

  if (!shouldShowConfetti) {
    return <></>;
  }

  return (
    <Box
      component={ReactConfetti}
      width={width}
      height={height}
      sx={{
        zIndex: '1400 !important',
      }}
      numberOfPieces={1400}
      tweenDuration={30000}
      recycle={false}
      colors={[primary.main, secondary.main]}
    />
  );
};
