import { Avatar, SxProps, Theme } from '@mui/material';
import { useProfilePicture } from 'hooks';
import { Loading } from '@guider-global/ui';

export interface IAvatarUserProps {
  width?: string | number;
  height?: string | number;
  sx?: SxProps<Theme>;
}
export function AvatarUser({ width, height, sx }: IAvatarUserProps) {
  const { initials, loading: isLoading, picture } = useProfilePicture();
  return (
    <Avatar
      variant="rounded"
      sx={{
        aspectRatio: '1',
        width: width ?? '100%',
        height: height ?? 'auto',
        backgroundColor: 'secondary.main',
        borderRadius: '4px',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        ...sx,
      }}
      src={picture}
    >
      {isLoading && (
        <Loading color="secondary" sx={{ width: width ?? '70%' }} />
      )}
      {!picture && !isLoading && initials}
    </Avatar>
  );
}
