import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  InputAdornment,
  ListItem,
  TextField,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { handleCustomFormErrors } from 'utils';
import { TFormAutocomplete } from './types';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

// TODO Rename to FormDropdown when FormDropdown deprecated
export const FormAutocomplete = <
  TFieldValues extends FieldValues,
  FieldType extends PathValue<TFieldValues, Path<TFieldValues>> | undefined,
>({
  name,
  label,
  control,
  errors,
  options,
  rules,
  unregister,
  enabled = true,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  validateErrorMessage,
  requiredErrorMessage,
  emptyOption = false,
  helperText,
  defaultValue,
  multiple,
  ...selectProps
}: TFormAutocomplete<TFieldValues>) => {
  const sx = {
    label: { color: 'text.secondary', fontWeight: 400 },
    display: !enabled ? 'none' : undefined,
    ...selectProps.sx,
  };
  const errorColor = 'error.main';
  const hasError = !!errors[name];
  const errorSx = hasError ? { color: errorColor } : undefined;

  const theme = useTheme();
  const { info: infoColor } = theme.palette;

  useEffect(() => {
    if (!enabled && unregister) {
      unregister(name);
    } else if (!enabled && !unregister) {
      const message = `The FormField ${name} is not enabled but still registered in the React Hook Form - you probably forgot to pass the 'unregister' prop to the FormField!`;
      console.log(message);
      throw new Error(message);
    }
  }, [enabled, name, unregister]);

  if (!enabled) return null;

  return (
    <FormControl
      fullWidth
      sx={{
        mt: 2.5,
      }}
    >
      <Controller<TFieldValues>
        name={name}
        control={control}
        rules={rules}
        defaultValue={
          (defaultValue as FieldType) ??
          (multiple ? ([] as FieldType) : ('' as FieldType))
        }
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Autocomplete
                multiple={multiple}
                options={options}
                disableCloseOnSelect={multiple}
                onChange={(_event, value) => {
                  const parsedValue = Array.isArray(value)
                    ? value.map((value) => {
                        if (typeof value === 'string') {
                          return value;
                        }
                        return value?.value;
                      })
                    : typeof value === 'string'
                    ? value
                    : (value as { label: string; value: string })?.value;
                  onChange(parsedValue);
                }}
                isOptionEqualToValue={(option, value) => {
                  if (
                    (typeof value === 'string' && option.value === value) ||
                    option.value === value.value
                  ) {
                    return true;
                  }

                  return false;
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return (
                      options.find(
                        (initialOption) => initialOption.value === option,
                      )?.label ?? ''
                    );
                  } else {
                    return option.label ?? '';
                  }
                }}
                value={value}
                renderOption={(props, option, { selected }) => (
                  <ListItem
                    {...props}
                    sx={{
                      '&[aria-selected="true"]': {
                        backgroundColor: `${infoColor.main} !important`,
                        color: infoColor.contrastText,
                      },
                    }}
                    key={typeof option === 'string' ? option : option.value}
                  >
                    {multiple && (
                      <Checkbox
                        style={{
                          marginRight: 8,
                          color: selected
                            ? infoColor.contrastText
                            : infoColor.main,
                        }}
                        checked={selected}
                      />
                    )}
                    {option.label}
                  </ListItem>
                )}
                renderInput={(params) => {
                  const { InputProps, ...restParams } = params;
                  const { endAdornment, ...restInputProps } = InputProps;
                  return (
                    <TextField
                      {...restParams}
                      variant="outlined"
                      label={label}
                      color="info"
                      error={hasError}
                      data-cy={`components_FormAutocomplete_${name}`}
                      InputLabelProps={{
                        sx: {
                          color: hasError ? errorColor : 'text.secondary',
                          fontWeight: 400,
                          '&.Mui-focused': {
                            color: hasError ? errorColor : 'info.main',
                          },
                        },
                      }}
                      InputProps={{
                        ...restInputProps,
                        sx: {
                          '& > .MuiInputAdornment-root': {
                            height: 'fit-content',
                            marginRight: '-25px',
                            backgroundColor: theme.palette.background.paper,
                            zIndex: 1,
                          },
                          '& > .MuiAutocomplete-endAdornment > .MuiAutocomplete-clearIndicator':
                            {
                              visibility: hasError ? 'hidden' : undefined,
                            },
                        },
                        endAdornment: hasError ? (
                          <>
                            <InputAdornment position="end">
                              <InfoOutlined sx={errorSx} />
                            </InputAdornment>
                            {endAdornment}
                          </>
                        ) : (
                          endAdornment
                        ),
                      }}
                    />
                  );
                }}
                {...selectProps}
                sx={sx}
              />
            </>
          );
        }}
      />
      <FormHelperText sx={errorSx}>
        <>
          {handleCustomFormErrors(
            errors[name],
            maxLengthErrorMessage,
            minLengthErrorMessage,
            validateErrorMessage,
            requiredErrorMessage,
          ) ?? helperText}
        </>
      </FormHelperText>
    </FormControl>
  );
};
