// external
import React, { useEffect } from 'react';
import { AlertColor } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { connect, ConnectedProps } from 'react-redux';

// components
import { SnackbarAlert } from 'components';

// store
import { hideAppAlert } from 'store/slices/appSlice';
import { RootState } from 'store';

const mapState = (state: RootState) => {
  return {
    appAlert: state.app.alert,
  };
};

const mapDispatch = { hideAppAlert };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

const AlertBoxComponent: React.FunctionComponent<PropsFromRedux> = ({
  appAlert,
  hideAppAlert,
}) => {
  const { severity, message, show, timeout } = appAlert;

  useEffect(() => {
    if (!show) return;
    const timer = setTimeout(() => {
      hideAppAlert(true);
    }, timeout);

    return () => clearTimeout(timer);
  }, [show, timeout, hideAppAlert]);

  const getIconForSeverity = (severityLabel: AlertColor) => {
    switch (severityLabel) {
      case 'success':
        return <CheckCircleOutline />;
      case 'warning':
        return <ReportProblemOutlined />;
      case 'info':
        return <InfoOutlined />;
      case 'error':
        return <ErrorOutline />;
      default:
        return <></>;
    }
  };

  return (
    <SnackbarAlert
      severity={severity}
      alertContent={message}
      open={show}
      icon={getIconForSeverity(severity)}
      sx={{ mr: 6 }}
    />
  );
};

export const AlertBox = connector(AlertBoxComponent);
