import { useAuth } from '@guider-global/auth-hooks';
import {
  IGetBlobObjectUrlResult,
  getBlobObjectUrl,
} from '@guider-global/azure-storage-hooks';
import { useAxios } from '@guider-global/redux-axios-hooks';
import { IError, IStorageResponse } from '@guider-global/shared-types';
import { Box, SxProps, Theme } from '@mui/material';
import Compressor from 'compressorjs';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { uploadToBlob } from 'utils';
import { Button } from '@guider-global/ui';

interface IFileUploadProps {
  uploadApiPath: string;
  sasTokenApiPath: string;
  containerName: string;
  showImagePreview?: boolean;
  showErrors?: boolean;
  onBeforeUpload: () => Promise<void>;
  onUploadSuccess: (fileUri: string, blobObjectUrl: string) => Promise<void>;
  onUploadError: (errors: IError[]) => Promise<void>;
  boxSx?: SxProps<Theme>;
  buttonIcon?: JSX.Element;
  buttonUploadText?: string;
  buttonUploadedText?: string;
  buttonDisabled?: boolean;
  color?:
    | 'inherit'
    | 'error'
    | 'success'
    | 'info'
    | 'warning'
    | 'primary'
    | 'secondary';
  defaultFile?: File | Blob;
}

export const FileUpload: React.FunctionComponent<IFileUploadProps> = ({
  uploadApiPath,
  sasTokenApiPath,
  containerName,
  showImagePreview = false,
  showErrors = true,
  onBeforeUpload,
  onUploadSuccess,
  onUploadError,
  boxSx = {},
  buttonIcon = undefined,
  buttonUploadText = 'Choose a photo',
  buttonUploadedText = 'Change photo',
  buttonDisabled = false,
  color,
  defaultFile,
}) => {
  // Auth
  const { accessToken, getAccessToken } = useAuth({});

  // Axios
  const { requestCallback } = useAxios({
    waitForAuthentication: true,
    accessToken,
    onExpiredAccessToken: getAccessToken,
  });

  // Lifecycle state
  const [defaultFileHandled, setDefaultFileHandled] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errors, setErrors] = useState<IError[]>([]);

  // Blob storage / CDN
  const [blobName, setBlobName] = useState<string>('');
  const [fileUri, setFileUri] = useState<string | null>(null);

  // Local blob storage
  const [blobObjectUrl, setBlobObjectUrl] = useState<string>('');

  const uploadFileToBlob = useCallback(
    async (file: File, filename: string) => {
      try {
        // Create upload url
        const axiosResult = await requestCallback<IStorageResponse>({
          method: 'POST',
          url: uploadApiPath,
          params: { filename },
        });
        const { data: axiosData } = axiosResult;
        const { data } = axiosData;
        // Handle result
        if (!data || data?.length <= 0) {
          const { errors: axiosErrors } = axiosData;
          setError(true);
          setErrors(axiosErrors ?? []);
          return;
        }
        const { sasToken, uri, blobName: blobNameResult } = data[0];
        // Upload to blob
        const uploadResult = await uploadToBlob(uri, sasToken, file);
        if (uploadResult.status === 'error') {
          setError(true);
          setErrors(uploadResult.errors);
          return;
        }
        setBlobName(blobNameResult);
        setFileUri(uri);
        setUploaded(true);
      } catch (err: unknown) {
        setError(true);
        if (err instanceof Error) {
          setErrors([{ message: err.message }]);
        }
        setErrors([{ message: 'Unknown error occurred uploading file' }]);
      }
    },
    [requestCallback, uploadApiPath],
  );

  const compressFile = useCallback(
    async (file: Blob | File) => {
      try {
        const opts: Compressor.Options = {
          checkOrientation: true,
          maxHeight: 400,
          maxWidth: 400,
          quality: 0.9,
          mimeType: 'image/jpeg',
          resize: 'cover',
          convertSize: 600000, // 600kb
          retainExif: false,
          strict: false,
        };
        new Compressor(file, {
          ...opts,
          async success(result: File) {
            const filename = result.name ?? new Date().toISOString();
            setUploading(true);
            await uploadFileToBlob(result, filename);
          },
          error(err: Error) {
            setError(true);
            setErrors([{ ...err }] ?? [{ message: 'Could not compress file' }]);
          },
        });
      } catch (err: unknown) {
        setError(true);
        setErrors([{ message: 'Could not compress file' }]);
      }
    },
    [uploadFileToBlob],
  );

  async function onFileChange(
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> {
    let img;
    if (event.target.files && event.target.files[0]) {
      img = event.target.files[0];
    }

    handleFileChange();
    if (!img) {
      return;
    }
    await compressFile(img);
  }

  const handleFileChange = useCallback(function () {
    setUploaded(false);
    setDownloaded(false);
    setFinished(false);
    setError(false);
    setErrors([]);
    setFileUri('');
    setBlobObjectUrl('');
  }, []);

  useEffect(() => {
    if (defaultFile && !defaultFileHandled) {
      handleFileChange();
      compressFile(defaultFile);
      setDefaultFileHandled(true);
    }
  }, [
    compressFile,
    defaultFile,
    defaultFileHandled,
    handleFileChange,
    setDefaultFileHandled,
  ]);

  // Handle before uploading
  useEffect(() => {
    if (uploading && !finished) {
      onBeforeUpload();
    }
  }, [uploading, finished, onBeforeUpload]);

  // Handle downloading file
  useEffect(() => {
    async function downloadFile(downloadFileBlobName: string) {
      try {
        // Get SAS token
        const axiosResult = await requestCallback<IStorageResponse>({
          method: 'POST',
          url: sasTokenApiPath,
          params: {
            containerName,
            permissions: 'r',
            blobName: '',
          },
        });
        const { data: axiosData } = axiosResult;
        const { data } = axiosData;
        if (!data || data.length <= 0) {
          console.log('ERROR: Failed to download file');
          const { errors: axiosErrors } = axiosData;
          setError(true);
          setErrors(axiosErrors ?? []);
          return;
        }
        const { sasToken, url } = data[0];
        // Download file
        const result: IGetBlobObjectUrlResult = await getBlobObjectUrl(
          url,
          sasToken,
          containerName,
          downloadFileBlobName,
        );
        const { objectUrl } = result;
        if (result.errors) {
          setError(true);
          setErrors([...result.errors]);
        } else {
          setDownloaded(true);
          if (fileUri && objectUrl) {
            onUploadSuccess(fileUri, objectUrl);
          } else {
            console.error({
              fileUri: fileUri ?? '',
              blobObjectUrl,
            });
            throw new Error('Error getting fileUri/blobObjectUrl');
          }
          setFinished(true);
        }
      } catch (err: unknown) {
        setError(true);
        if (err instanceof Error) {
          console.log(err.message);
          setErrors([{ message: err.message } as IError]);
        }
        console.log('ERROR: Failed to download file');
      }
    }
    if (uploaded && !downloaded && fileUri && blobName) {
      downloadFile(blobName);
    }
  }, [
    blobName,
    blobObjectUrl,
    containerName,
    downloaded,
    fileUri,
    onUploadSuccess,
    requestCallback,
    sasTokenApiPath,
    uploaded,
  ]);

  // Handle errors before finishes
  useEffect(() => {
    if (error && errors.length > 0 && !finished) {
      onUploadError(errors);
      setFinished(true);
    }
  }, [error, errors, finished, onUploadError]);

  return (
    <>
      {showImagePreview ? (
        <img src={blobObjectUrl} alt="Uploaded preview" />
      ) : null}
      {showErrors && errors
        ? errors.map((err) => JSON.stringify(err.message))
        : null}
      <Box sx={{ ...boxSx }}>
        <input
          type="file"
          accept="image/*;capture=camera"
          onChange={onFileChange}
          onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            const element = event.target as HTMLInputElement;
            element.value = '';
          }}
          id="file-upload"
          data-cy="components_FileUpload_file-upload-button"
          hidden
        />
        <Button
          variant="outlined"
          sx={{ px: 3, height: 'fit-content' }}
          disabled={false}
          color="info"
          onClick={() => {
            document.getElementById('file-upload')?.click();
          }}
        >
          {!uploaded ? buttonUploadText : buttonUploadedText}
        </Button>
      </Box>
    </>
  );
};
