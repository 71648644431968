import { SendMessageOptions } from '@azure/communication-chat';
import {
  DEFAULT_COMPONENT_ICONS,
  MessageThread,
  SendBox,
  usePropsFor,
} from '@azure/communication-react';
import { initializeIcons, registerIcons } from '@fluentui/react';
import { Box, Chip, Fade } from '@mui/material';

interface ChatComponentsProps {
  isGuide: boolean;
  isGroupProgram: boolean;
  isNotLiveGroup: boolean;
  archived?: boolean;
  archivedLabel?: string;
  onMessageSeen?: (messageId: string) => Promise<void>;
  onSendMessage?: () => Promise<void>;
}

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

export const ChatComponents = ({
  isGuide,
  isGroupProgram,
  isNotLiveGroup,
  archived,
  archivedLabel,
  onMessageSeen,
  onSendMessage,
}: ChatComponentsProps) => {
  const isNotLiveGroupAndIsTrainee = isNotLiveGroup && !isGuide; // messaging is not available for trainees in groups pending activation
  const canSendMessages = isGroupProgram
    ? !archived && !isNotLiveGroupAndIsTrainee // messaging is available in other cases (guides in groups pending activation, guides & trainees in live groups)
    : !archived;
  const messageThreadProps = usePropsFor(MessageThread);
  messageThreadProps.messages = messageThreadProps.messages.filter(
    (message) => message.messageId,
  );
  const sendBoxProps = usePropsFor(SendBox);
  const defaultNumberOfChatMessagesToReload = 2;

  const handleMessageSend = async (
    content: string,
    options?: SendMessageOptions | undefined,
  ) => {
    if (isGroupProgram && isNotLiveGroupAndIsTrainee) return;
    if (onSendMessage) {
      await onSendMessage();
    }
    await sendBoxProps.onSendMessage(content, options);
  };

  return (
    <Fade in={true}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr min-content',
          position: 'relative',
        }}
      >
        {sendBoxProps && archived && (
          <Chip
            label={archivedLabel}
            variant="filled"
            color="info"
            sx={{
              position: 'absolute',
              zIndex: 2,
              left: '50%',
              top: 12,
              transform: 'translate(-50%, 0)',
            }}
          />
        )}
        <Box
          sx={{
            position: 'relative',
            '.fui-Chat': {
              minWidth: '100%',
            },
          }}
        >
          {/*Props are updated asynchronously, so only render the component once props are populated.*/}
          {messageThreadProps && (
            <MessageThread
              {...messageThreadProps}
              onSendMessage={handleMessageSend}
              showMessageDate
              onMessageSeen={onMessageSeen}
              numberOfChatMessagesToReload={defaultNumberOfChatMessagesToReload}
              styles={{
                root: {
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-end',
                },
                chatContainer: {
                  overflow: 'auto',
                },
              }}
              disableEditing={archived}
            />
          )}
        </Box>
        {sendBoxProps && canSendMessages && (
          <SendBox
            {...sendBoxProps}
            onSendMessage={handleMessageSend}
            styles={{
              root: {
                order: 2,
              },
            }}
          />
        )}
      </Box>
    </Fade>
  );
};
